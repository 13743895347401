import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import Box from 'shopper/components/Box';
import Text from 'shopper/components/Text';

import LazyHydratedGooglePublisherTagAdSlot from 'components/AdSlots/GooglePublisherTagAdSlot/LazyHydratedGooglePublisherTagAdSlot';

import { omit } from 'lib/object';
import placeholder from 'lib/placeholder';

const AD_WIDTH_IN_PX = 300;
const AD_HEIGHT_IN_PX = 250;

const SidebarGoogleAd = forwardRef(
  (
    {
      adId,
      adPath,
      adSizes = [AD_WIDTH_IN_PX, AD_HEIGHT_IN_PX],
      adResponsiveSizesMap = [],
      adProps,
      forwardedRef = null,
      showAdSlotOnBreakpoints = [false, null, null, true],
      className,
      ...rest
    },
    ref
  ) => (
    <Box
      ref={forwardedRef || ref}
      className={twMerge(
        'hidden h-[300px] flex-col items-center md:flex',
        className
      )}
      {...rest}
    >
      <Text
        className="hidden px-6 py-4 text-neutral-high-500 dark:text-neutral-high-500 xl:block"
        size="size3"
      >
        {placeholder('titles.paidMedia')}
      </Text>
      <LazyHydratedGooglePublisherTagAdSlot
        adId={adId}
        adPath={adPath}
        adResponsiveSizesMap={adResponsiveSizesMap}
        adSizes={adSizes}
        className={twMerge('h-[300px]', adProps?.className)}
        showAdSlotOnBreakpoints={showAdSlotOnBreakpoints}
        {...omit(adProps, ['className'])}
      />
    </Box>
  )
);

SidebarGoogleAd.displayName = 'SidebarGoogleAd';

SidebarGoogleAd.propTypes = {
  adId: PropTypes.string.isRequired,
  adPath: PropTypes.string.isRequired,
  adSizes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ),
    ])
  ),
  adResponsiveSizesMap: PropTypes.arrayOf(
    PropTypes.shape({
      viewport: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
      sizesMap: PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.arrayOf(PropTypes.number),
        ])
      ).isRequired,
    })
  ),
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  showAdSlotOnBreakpoints: PropTypes.arrayOf(PropTypes.bool),
};

export default SidebarGoogleAd;
